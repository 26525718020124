<!--碗卡激活页面-->
<template>
    <div>
        <div class="top-wrapper">
            <img class="top-card" src="https://seetop-1257860468.file.myqcloud.com/html/img/registerBanner.png">
            <div class="top-info-wrapper">
                <img class="top-head" :src="user.headimgurl"  v-if="user.headimgurl">
                <img class="top-head" src="https://seetop-1257860468.file.myqcloud.com/html/img/anonymous.png" v-else>
                <span v-if="user.weixinNick">{{user.weixinNick}}</span>
                <span v-else>匿名</span>
            </div>
        </div>
        <div class="input-wrapper">
            <i class="iconfont icon-bangdingyinhangqia icon-label"></i>
            <span class="input-label">卡号</span>
            <input class="input" type="tel" placeholder="请输入您的会员卡号" v-model="newCard">
        </div>
        <div class="input-wrapper">
            <i class="iconfont icon-mima icon-label"></i>
            <span class="input-label">密码</span>
            <input class="input" placeholder="请输入您的会员卡密码" v-model="password">
        </div>
        <div class="activation-btn" @click="activation">立即激活</div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  import FloatNav from '../../components/common/FloatNav'

  export default {
    name: 'Activation',
    components: { FloatNav },
    mixins: [globalConfig],
    data () {
      return {
        user: [],
        isBindUser: 0,
        newCard: '',
        password: ''
      }
    },
    methods: {
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/user').then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            that.user = data.info
            that.isBindUser = data.isBindUser
          }
        })
      },
      activation: function () {
        const that = this
        if (this.isBindUser) {
            if (that.newCard && that.password) {
                if (that.user.cardnum) {
                  that.$dialog.confirm({
                    title: '提醒',
                    message: '您已持有碗卡卡号' + that.user.cardnum + '，新卡卡号为' + that.newCard + '，是否保留原有卡号' + '<p style=\'color: red\'>*月卡升级碗卡，将会清空月卡时间</p>',
                    confirmButtonText: '使用新卡号',
                    cancelButtonText: '保留旧卡号'
                  }).then(() => {
                    that.postData(0)
                  }).catch(() => {
                    that.postData(1)
                  })
                } else {
                  this.postData(0)
                }
            } else {
              this.$dialog('请填写完整碗卡信息')
            }
        } else {
          this.$dialog.confirm({
            title: '提示',
            message: '您未绑定手机号码，请前往注册！'
          }).then(() => {
            that.$router.push('/bind?url=' + that.webUrl + '#/Activation')
          }).catch(() => {})
        }
      },
      postData: function (isKeep) {
        const that = this
        this.axios.post(this.apiUrl + 'mall/user/register', {
          card: this.newCard,
          password: this.password,
          isKeep: isKeep
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
            that.$toast({
              message: '激活成功',
              onClose: function () {
                that.$router.push('/user')
              }
            })
          } else {
            that.$toast(data.msg)
          }
        })
      }
    },
    mounted () {
      this.init()
      this.newCard = this.$route.params.card
      this.password = this.$route.params.password
    }
  }
</script>

<style scoped>
    .top-wrapper{
        position: relative;
    }
    .top-card{
        width: 100%;
        height: 13rem;
    }
    .top-head{
        width: 4rem;
        height: 4rem;
        border-radius: 5rem;
        margin: 2vw auto;
    }
    .input-wrapper{
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;
    }
    .input-label{
        font-size: 1rem;
        margin-left: 1rem;
        color: #666666;
    }
    .top-info-wrapper{
        display: flex;
        flex-flow: column;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4rem;
        text-align: center;
        color: #666;
        font-size: 3.8vw;
    }
    .input{
        border-radius: 2rem;
        border: 0.01rem solid #d29713;
        font-size: 1rem;
        height: 2rem;
        line-height: 2rem;
        margin-left: 1rem;
        padding-left: 1rem;
        width: 13rem;
    }
    .input::placeholder{
        font-size: 1rem;
    }
    .icon-label{
        font-size: 1.4rem;
        color: #d29713;
    }
    .activation-btn{
        background-color: #d29713;
        border-radius: 5rem;
        width: 18rem;
        color: #ffffff;
        margin: 3rem auto;
        text-align: center;
        padding: 0.4rem 0;
        font-size: 1rem;
    }
</style>
